.custom-gallery .image-gallery-slide img {
  max-height: 60vh; /* Ajustez la hauteur maximale des images */
  object-fit: contain;
}

.custom-gallery .image-gallery-content {
  max-width: 80vw; /* Limitez la largeur de la galerie */
  margin: auto;
}

.custom-gallery .image-gallery-left-nav,
.custom-gallery .image-gallery-right-nav {
  width: 30px; /* Réduisez la largeur des flèches */
  height: 50px; /* Ajustez la hauteur si nécessaire */
  background: rgba(0, 0, 0, 0.5); /* Ajoutez un arrière-plan semi-transparent */
}

.custom-gallery .image-gallery-left-nav:before,
.custom-gallery .image-gallery-right-nav:before {
  font-size: 24px; /* Ajustez la taille de la flèche */
  color: white; /* Couleur des flèches */
}

.image-gallery-slide-wrapper .image-gallery-slide .image-gallery-image {
  max-width: 70%; /* Limitez la largeur de l'image */
  margin: auto;
}

/* === BUBBLES SECTION === */
.bubbles-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1rem;
  background: #e25822;
  filter: url("#blob");
}

.bubbles-top .bubble-top {
  position: absolute;
  left: var(--position, 50%);
  background: #e25822;
  border-radius: 100%;
  animation:
      bubble-size var(--time, 4s) ease-in infinite var(--delay, 0s),
      bubble-move var(--time, 4s) ease-in infinite var(--delay, 0s);
  transform: translate(-50%, 100%);
}

.bubbles-bottom {
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
  height: 1rem;
  background: #e25822;
  filter: url("#blob");
}

.bubbles-bottom .bubble-bottom {
  position: absolute;
  left: var(--position, 50%);
  background: #e25822;
  border-radius: 100%;
  animation:
      bubble-size var(--time, 4s) ease-in infinite var(--delay, 0s),
      bubble-move-down var(--time, 4s) ease-in infinite var(--delay, 0s);
  transform: translate(-50%, 100%);
}

#bubbles-container-top,
#bubbles-container-bottom {
  position: absolute; /* Nécessaire pour z-index */
  width: 100%;
  height: 100px;
  overflow: visible;
  z-index: 0; /* Derrière */
}

/* === ANIMATIONS === */
@keyframes bubble-size {
  0%, 50% {
      width: var(--size, 4rem);
      height: var(--size, 4rem);
  }
  100% {
      width: 0rem;
      height: 0rem;
  }
}

@keyframes bubble-move {
  0% {
      bottom: -4rem;
  }
  100% {
      bottom: var(--distance, 10rem);
  }
}

@keyframes bubble-move-down {
  0% {
      top: -4rem;
  }
  100% {
      top: var(--distance, 10rem);
  }
}
