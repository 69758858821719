.custom-gallery .image-gallery-icon {
  display: none; /* This hides the arrows */
}

/* If you want to customize the arrows instead of hiding them */
.custom-gallery .image-gallery-left-nav,
.custom-gallery .image-gallery-right-nav {
  background: transparent !important; /* Make the background transparent */
  border: none !important; /* Remove any borders */
}

.custom-gallery .image-gallery-left-nav:before,
.custom-gallery .image-gallery-right-nav:before {
  content: ''; /* Remove the default arrow content */
}


.skeleton-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.skeleton-image {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
}


