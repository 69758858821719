.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  z-index: 9999;
}

.ReactModal__Content {
  overflow: auto;
  max-height: 100vh;
}