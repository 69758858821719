.ReactFlagsSelect-module_selectOptions__3LNBJ {
    position: absolute;
    z-index: 999999;
    border: 1px solid #bdbbbb;
    border-radius: 3px;
    background: #ffffff;
    margin-top: 8px;
    padding: 8px 0;
    max-height: 300px; /* Increase this value to show more items */
    overflow: auto;
}